import React from "react";
import { Route, Routes as Switch } from "react-router-dom";
import Home from "./containers/Home";
import Sources from "./containers/Sources";
import Sheets from "./containers/Sheets";
import Wpt from "./containers/Wpt";
import NotFound from "./containers/NotFound";

import Login from "./containers/auth/Login";
import Signup from "./containers/auth/Signup";
import Settings from "./containers/auth/Settings";
import ResetPassword from "./containers/auth/ResetPassword";
import ChangePassword from "./containers/auth/ChangePassword";
import ChangeEmail from "./containers/auth/ChangeEmail";

import NewSource from "./containers/sources/NewSource";
import Source from "./containers/sources/Source";

import NewSheet from "./containers/sheets/NewSheet";
import Sheet from "./containers/sheets/Sheet";

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<UnauthenticatedRoute element={<Login />} />} />
      <Route path="/login/reset" element={ <UnauthenticatedRoute element={<ResetPassword />} />} />
      <Route path="/signup" element={<UnauthenticatedRoute element={<Signup />} />} />
      <Route path="/settings" element={<AuthenticatedRoute element={<Settings />} />} />
      <Route path="/settings/password" element={<AuthenticatedRoute element={<ChangePassword />} />} />
      <Route path="/settings/email" element={<AuthenticatedRoute element={<ChangeEmail />} />} />

      <Route path="/sources" element={<AuthenticatedRoute element={<Sources />} />} />
      <Route path="/sources/new" element={<AuthenticatedRoute element={<NewSource />} />} />
      <Route path="/sources/:id" element={<AuthenticatedRoute element={<Source />} />} />

      <Route path="/sheets" element={<AuthenticatedRoute element={<Sheets />} />} />
      <Route path="/sheets/new" element={<AuthenticatedRoute element={<NewSheet />} />} />
      <Route path="/sheets/:id" element={<AuthenticatedRoute element={<Sheet />} />} />

      <Route path="/wpt" element={<AuthenticatedRoute element={<Wpt />} />} />

      {/* Finally, catch all unmatched routes */}
      <Route path="*" element={<NotFound />} />
    </Switch>
  );
}