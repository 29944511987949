import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useAppContext } from "../lib/contextLib";

function waitForInit() {
  return new Promise((res, rej) => {
    const hasFbLoaded = () => {
      if (window.FB) {
        res();
      } else {
        setTimeout(hasFbLoaded, 300);
      }
    };
    hasFbLoaded();
  });
}

export default function FacebookButton() {
  const [isLoading, setIsLoading] = useState(true);
  const { userHasAuthenticated } = useAppContext();

  useEffect(() => {
    onLoad();
  }, []);
  
  async function onLoad() {
    await waitForInit();
    setIsLoading(false);
  }

  const statusChangeCallback = response => {
    console.log("facebook login status check response:", response);
    if (response.status === "connected") {
      handleResponse(response.authResponse);
    } else {
      handleError(response);
    }
  };

  const checkLoginState = () => {
    window.FB.getLoginStatus(statusChangeCallback);
  };

  const handleClick = () => {
    window.FB.login(checkLoginState, {scope: "public_profile,email"});
  };

  const  handleError = (error) => {
    alert(error);
  }

  async function handleResponse(data) {
    const { email, accessToken: token, expiresIn } = data;
    const expires_at = expiresIn * 1000 + new Date().getTime();
    const user = { email };

    setIsLoading(true);

    try {
      const response = await Auth.federatedSignIn(
        "facebook",
        { token, expires_at },
        user
      );
      console.log("facebook login success response:", response);
      setIsLoading(false);
      userHasAuthenticated(true);
    } catch (e) {
      setIsLoading(false);
      handleError(e);
    }
  }

  return (
    <LoadingButton
      loading={isLoading}
      disabled={isLoading}
      loadingPosition="start"
      startIcon={<SaveIcon />}
      variant="outlined"
      onClick={handleClick}
    >
      Login with Facebook
    </LoadingButton>
  );
}