import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useFormFields } from "../../lib/hooksLib";
import { onError } from "../../lib/errorLib";

export default function ChangePassword() {
  const navigate = useNavigate();
  const [fields, handleFieldChange] = useFormFields({
    password: "",
    oldPassword: "",
    confirmPassword: "",
  });
  const [isChanging, setIsChanging] = useState(false);

  function validateForm() {
    return (
      fields.oldPassword.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  async function handleChangeClick(event) {
    event.preventDefault();

    setIsChanging(true);

    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        currentUser,
        fields.oldPassword,
        fields.password
      );

      navigate("/settings");
    } catch (error) {
      onError(error);
      setIsChanging(false);
    }
  }

  return (
    <div className="ChangePassword">
     <form onSubmit={handleChangeClick} style={{display: "flex", flexDirection: "column"}}>
        <TextField
          id="oldPassword"
          label="Old Password"
          type="password"
          value={fields.oldPassword}
          onChange={handleFieldChange}
        />    
        <hr />
        <TextField
          id="password"
          label="New Password"
          type="password"
          value={fields.password}
          onChange={handleFieldChange}
        />    
        <TextField
          id="confirmPassword"
          label="Confirm Password"
          type="password"
          value={fields.confirmPassword}
          onChange={handleFieldChange}
        />    

        <LoadingButton
          loading={isChanging}
          disabled={!validateForm()}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="outlined"
          type="submit"
        >
          Change Password
        </LoadingButton>
        
      </form>
    </div>
  );
}