import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { onError } from "../../lib/errorLib";
import config from "../../config";
import { s3UploadSource } from "../../lib/awsLib";
import Papa from "papaparse";
import {createDataModel, generateMemoryLookups} from "../../services/MemoryService";
import Constants from "../../Constants";

export default function NewSheet() {
  const file = useRef(null);
  const navigate = useNavigate();
  const [sheetName, setSheetName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return sheetName.length > 0;
  }

  function handleFileChange(event) {
    file.current = event.target.files[0];
  }

  async function parseCsvFile (file) {

    let json = new Promise((resolve, reject) => {
      Papa.parse(file, {
        delimiter: ",",
        worker: false,
        skipEmptyLines: true,
        header: false,
        dynamicTyping: false,
        complete (results, file) {
          resolve(results)
        },
        error (err, file) {
          reject(err)
        }
      })
    })
    let result = await json
    return result.data;      
     
  }

  async function handleSubmit(event) {
    event.preventDefault();
    console.log("file", file);

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }

    setIsLoading(true);   

    const data = await parseCsvFile(file.current);

    console.log("parsed data", data);

    const attrArray = data[0];
    const dataArray = data.splice(1)

    const fields = createDataModel(attrArray, dataArray, Constants);

    console.log("parsed fields", fields);

    const sheet = {
      type: Constants.sheetType.CSV,
      mode: Constants.sheetMode.MEMORY,
      url: file.current.name,
      delimiter: ",",
      data: dataArray,
      fields: fields,
    }

    generateMemoryLookups (sheet);

    try {
      const sheetFileKey = await s3UploadSource(sheet);
  
      await createSheet({ sheetName, sheetFileKey });
      navigate("/sheets");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }  
  
  function createSheet(sheet) {
    return API.post("wpt-app", "/sheets", {
      body: sheet
    });
  }

  return (
    <form onSubmit={handleSubmit} style={{display: "flex", flexDirection: "column"}}>
      <TextField
        label="Sheet name"
        value={sheetName}
        onChange={(e) => setSheetName(e.target.value)}
      />        

      <Button
        containerelement='label' // <-- Just add me!
        label='Load a sheet file (csv or excel)'>
        <input type="file" id="file" onChange={handleFileChange} />
      </Button>

        <LoadingButton
          loading={isLoading}
          disabled={!validateForm()}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="outlined"
          type="submit"
        >
          Create
        </LoadingButton>

    </form>
  );
}