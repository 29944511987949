import React, { useState, useEffect } from "react";
import { useAppContext } from "../lib/contextLib";
import { onError } from "../lib/errorLib";
import { API } from "aws-amplify";
import { BsPencilSquare } from "react-icons/bs";
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from "react-router-dom";


export default function Sheets() {
  const navigate = useNavigate();
  const [sheets, setSheets] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
  
      try {
        const sheets = await loadSheets();
        setSheets(sheets);
      } catch (e) {
        onError(e);
      }
  
      setIsLoading(false);
    }
  
    onLoad();
  }, [isAuthenticated]);
  
  function loadSheets() {
    return API.get("wpt-app", "/sheets");
  }

  function renderSheetsList(sheets) {
    return (
      <List>
        <ListItem>
          <ListItemButton onClick={()=>{navigate("/sheets/new")} }>
            <ListItemIcon>
              <BsPencilSquare size={17} />
            </ListItemIcon>
            <ListItemText primary="Create a new sheet" />
          </ListItemButton>
        </ListItem>        
        {sheets.map(({ sheetId, sheetName, createdAt }) => (
          <ListItem key={sheetId} disablePadding>
            <ListItemButton onClick={()=>{navigate(`/sheets/${sheetId}`)} }>
              <ListItemText 
                primary={sheetName? sheetName : "remove me"} 
                secondary={"Created: "+new Date(createdAt).toLocaleString()}
              />
            </ListItemButton>
          </ListItem>        
        ))}
      </List>
    );
  }

  function renderLander() {
    return (
      <Paper>
        <h1>WPT</h1>
        <p>Data analytics app</p>
      </Paper>
    );
  }

  function renderSheets() {
    return (
      <Paper>
        <h2>Your Sheets</h2>
        <Paper>{!isLoading && renderSheetsList(sheets)}</Paper>
      </Paper>
    );
  }

  return (
    <div>
      {isAuthenticated ? renderSheets() : renderLander()}
    </div>
  );
}