import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useFormFields } from "../../lib/hooksLib";
import { onError } from "../../lib/errorLib";

export default function ChangeEmail() {
  const navigate = useNavigate();
  const [codeSent, setCodeSent] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    code: "",
    email: "",
  });
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);

  function validateEmailForm() {
    return fields.email.length > 0;
  }

  function validateConfirmForm() {
    return fields.code.length > 0;
  }

  async function handleUpdateClick(event) {
    event.preventDefault();

    setIsSendingCode(true);

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, { email: fields.email });
      setCodeSent(true);
    } catch (error) {
      onError(error);
      setIsSendingCode(false);
    }
  }

  async function handleConfirmClick(event) {
    event.preventDefault();

    setIsConfirming(true);

    try {
      await Auth.verifyCurrentUserAttributeSubmit("email", fields.code);

      navigate("/settings");
    } catch (error) {
      onError(error);
      setIsConfirming(false);
    }
  }

  function renderUpdateForm() {
    return (
      <form onSubmit={handleUpdateClick} style={{display: "flex", flexDirection: "column"}}>
        <TextField
          id="email"
          label="Email"
          type="email"
          value={fields.email}
          onChange={handleFieldChange}
        />        
        <LoadingButton
          loading={isSendingCode}
          disabled={!validateEmailForm()}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="outlined"
          type="submit"
        >
          Update Email
        </LoadingButton>
      </form>
    );
  }

  function renderConfirmationForm() {
    return (
      <form onSubmit={handleConfirmClick} style={{display: "flex", flexDirection: "column"}}>
        <TextField
            id="code"
            label="Confirmation Code"
            type="tel"
            value={fields.code}
            onChange={handleFieldChange}
          />    
        <p>Please check your email ({fields.email}) for the code.</p>      

        <LoadingButton
          loading={isConfirming}
          disabled={!validateConfirmForm()}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="outlined"
          type="submit"
        >
          Confirm
        </LoadingButton>
      </form>
    );
  }

  return (
    <div className="ChangeEmail">
      {!codeSent ? renderUpdateForm() : renderConfirmationForm()}
    </div>
  );
}