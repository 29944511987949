import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import { BsCheck } from "react-icons/bs";
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useFormFields } from "../../lib/hooksLib";
import { onError } from "../../lib/errorLib";

export default function ResetPassword() {
  const [fields, handleFieldChange] = useFormFields({
    code: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [codeSent, setCodeSent] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);

  function validateCodeForm() {
    return fields.email.length > 0;
  }

  function validateResetForm() {
    return (
      fields.code.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  async function handleSendCodeClick(event) {
    event.preventDefault();

    setIsSendingCode(true);

    try {
      await Auth.forgotPassword(fields.email);
      setCodeSent(true);
    } catch (error) {
      onError(error);
      setIsSendingCode(false);
    }
  }

  async function handleConfirmClick(event) {
    event.preventDefault();

    setIsConfirming(true);

    try {
      await Auth.forgotPasswordSubmit(
        fields.email,
        fields.code,
        fields.password
      );
      setConfirmed(true);
    } catch (error) {
      onError(error);
      setIsConfirming(false);
    }
  }

  function renderRequestCodeForm() {
    return (
      <form onSubmit={handleSendCodeClick} style={{display: "flex", flexDirection: "column"}}>
        <TextField
            id="email"
            label="Email"
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />        

        <LoadingButton
          loading={isSendingCode}
          disabled={!validateCodeForm()}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="outlined"
          type="submit"
        >
          Send Confirmation
        </LoadingButton>


      </form>
         
    );
  }

  function renderConfirmationForm() {
    return (
      <form onSubmit={handleConfirmClick} style={{display: "flex", flexDirection: "column"}}>
        <TextField
            id="code"
            label="Confirmation Code"
            type="tel"
            value={fields.code}
            onChange={handleFieldChange}
          />    
        <p>Please check your email ({fields.email}) for the confirmation code.</p>      
        <hr />
        <TextField
            id="password"
            label="New Password"
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />    
        <TextField
            id="confirmPassword"
            label="Confirm Password"
            type="password"
            value={fields.confirmPassword}
            onChange={handleFieldChange}
          />    

        <LoadingButton
          loading={isConfirming}
          disabled={!validateResetForm()}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="outlined"
          type="submit"
        >
          Confirm
        </LoadingButton>

      </form>
    );
  }

  function renderSuccessMessage() {
    return (
      <div>
        <p><BsCheck size={16} /> Your password has been reset.</p>
        <p>
          <Link to="/login">
            Click here to login with your new credentials.
          </Link>
        </p>
      </div>
    );
  }

  return (
    <div>
      {!codeSent
        ? renderRequestCodeForm()
        : !confirmed
        ? renderConfirmationForm()
        : renderSuccessMessage()}
    </div>
  );
}