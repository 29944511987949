import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { API, Storage } from "aws-amplify";
import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
// import config from "../../config";
// import { s3Upload } from "../../lib/awsLib";
import { onError } from "../../lib/errorLib";

export default function Source() {
  // const file = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [source, setSource] = useState(null);
  const [sourceName, setSourceName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    function loadSource() {
      return API.get("wpt-app", `/sources/${id}`);
    }

    async function onLoad() {
      try {
        const source = await loadSource();
        console.log("source", source);


        if (source.sourceFileKey) {
          source.attachmentURL = await Storage.vault.get(source.sourceFileKey);
          // source.body = await s3DownloadSource(attachment)
        }

        setSourceName(source.sourceName);
        setSource(source);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [id]);

  function validateForm() {
    return sourceName.length > 0;
  }
  
  // function formatFilename(str) {
  //   return str.replace(/^\w+-/, "");
  // }
  
  // function handleFileChange(event) {
  //   file.current = event.target.files[0];
  // }
  
  function saveSource(source) {
    return API.put("wpt-app", `/sources/${id}`, {
      body: source
    });
  }
  
  async function handleSubmit(event) {
  
    event.preventDefault();
  
  
    setIsLoading(true);
  
    try {
  
      await saveSource({
        sourceName,
        sourceFileKey: source.sourceFileKey
      });
      navigate("/sources");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }
  
  function deleteSource() {
    return API.del("wpt-app", `/sources/${id}`);
  }
  
  async function handleDelete(event) {
    event.preventDefault();
  
    const confirmed = window.confirm(
      "Are you sure you want to delete this source?"
    );
  
    if (!confirmed) {
      return;
    }
  
    setIsDeleting(true);
  
    try {
      await deleteSource();
      navigate("/sources");
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }
  
  return (
    <div>
      {source && (
        <form onSubmit={handleSubmit} style={{display: "flex", flexDirection: "column"}}>
          <TextField
            label="Source Name"
            value={sourceName}
            onChange={(e) => setSourceName(e.target.value)}
          />        


          <LoadingButton
            loading={isLoading}
            disabled={!validateForm()}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
            type="submit"
          >
            Save
          </LoadingButton>
          <LoadingButton
            loading={isDeleting}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
            onClick={handleDelete}
          >
            Delete
          </LoadingButton>

        </form>
      )}
      {source && source.source && (<p> {JSON.stringify(source.source)}</p>)}

</div>
  );
}