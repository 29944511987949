const config = {
  SENTRY_DSN: "https://5dcfd40793c045fda5e559f2e506ea78@o1103376.ingest.sentry.io/6130062",
  STRIPE_KEY: "pk_test_51KBs0DDA2OHcawJXgqZwyICVjjfAZwEzeZvWL4eMixM0qD7Z3NVjPEZWlHjpHxe1LaHSas9n4brqokYM3FF3ROtZ00PhosOhHO",
  MAX_ATTACHMENT_SIZE: 5000000,
  // Backend config
  s3: {
    REGION: process.env.REACT_APP_REGION,
    BUCKET: process.env.REACT_APP_BUCKET,
  },
  apiGateway: {
    REGION: process.env.REACT_APP_REGION,
    URL: process.env.REACT_APP_API_URL,
  },
  cognito: {
    REGION: process.env.REACT_APP_REGION,
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
  },
  social: {
    FB: "1247331499107802"
  }  
};

export default config;