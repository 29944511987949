import React from "react";
import { useAppContext } from "../lib/contextLib";
import Paper from '@mui/material/Paper';


export default function Home() {
  const { isAuthenticated } = useAppContext();

  return (
    <Paper>
      <h1>Welcome {isAuthenticated? "back!" : "!"}</h1>
      <p>WPT - Data analytics app</p>
    </Paper>
  );
}