import { Storage } from "aws-amplify";

export async function s3Upload(file) {
  const filename = `${Date.now()}-${file.name}`;

  const stored = await Storage.vault.put(filename, file, {
    contentType: file.type,
  });

  return stored.key;
}

export async function s3UploadSource(source) {
  const sourceName = `${Date.now()}-${source.url}`;

  const stored = await Storage.vault.put(sourceName, source, {
    contentType: "application/json",
  });

  console.log("stored", stored);

  return stored.key;
}


export async function s3DownloadSource(key) {

  const stored = await Storage.get(key, {
    download: true,
    contentType: "application/json",
  });

  console.log("stored", stored);

  return stored.body;
}