import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useAppContext } from "../../lib/contextLib";
import { useFormFields } from "../../lib/hooksLib";
import { onError } from "../../lib/errorLib";
import { Auth } from "aws-amplify";
import FacebookButton from "../../components/FacebookButton";

export default function Signup() {
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  });
  const navigate = useNavigate();
  const [isConfirming, setIsConfirming] = useState(false);
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
  
    setIsLoading(true);
  
    try {
      await Auth.signUp({
        username: fields.email,
        password: fields.password,
      });
      setIsLoading(false);
      setIsConfirming(true);
    } catch (e) {
      if (e.code === "UsernameExistsException") {
        try {
          await Auth.resendSignUp(fields.email);
          setIsConfirming(true);
        } catch (e2) {
          if (e2.message === "User is already confirmed.") {
            onError(e);
          } else {
            onError(e2)
          }
        }
      } else {
        onError(e);
      }
      setIsLoading(false);
    }
  }
  
  async function handleConfirmationSubmit(event) {
    event.preventDefault();
  
    setIsLoading(true);
  
    try {
      await Auth.confirmSignUp(fields.email, fields.confirmationCode);
      const response = await Auth.signIn(fields.email, fields.password);
      console.log("acount login success response:", response);  
      userHasAuthenticated(true);
      navigate("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function renderConfirmationForm() {
    return (
      <form onSubmit={handleConfirmationSubmit} style={{display: "flex", flexDirection: "column"}}>
        <TextField
            id="confirmationCode"
            label="Confirmation Code"
            type="tel"
            value={fields.confirmationCode}
            onChange={handleFieldChange}
          />    
        <p>Please check your email for the code.</p>      

        <LoadingButton
          loading={isLoading}
          disabled={!validateConfirmationForm()}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="outlined"
          type="submit"
        >
          Verify
        </LoadingButton>


      </form>
    );
  }

  function renderForm() {
    return (
      <form onSubmit={handleSubmit} style={{display: "flex", flexDirection: "column"}}>
        <TextField
            id="email"
            label="Email"
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />        
        <TextField
            id="password"
            label="Password"
            value={fields.password}
            type="password"
            onChange={handleFieldChange}
          />    
        <TextField
            id="confirmPassword"
            label="Confirm Password"
            value={fields.confirmPassword}
            type="password"
            onChange={handleFieldChange}
          />    
        <LoadingButton
          loading={isLoading}
          disabled={!validateForm()}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="outlined"
          type="submit"
        >
          Signup
        </LoadingButton>

      </form>
    );
  }

  return (
    <div>
      <FacebookButton />      
      <hr/>

      {!isConfirming ? renderForm() : renderConfirmationForm()}
    </div>
  );
}