export const createDataModel = (attributes, dataArray, Constants) => {
  const isNumber = num =>
    (typeof num === "string" || typeof num === "number") &&
    !isNaN(num - 0) &&
    num !== "";

  const checkType = function(index) {
    for (let i = dataArray.length; i > 0; i--) {
      if (!isNumber(dataArray[i - 1][index])) {
        return Constants.fieldType.STRING;
      }
    }
    return Constants.fieldType.NUMBER;
  };

  return attributes.map((attr, index) => ({
    id: attr,
    name: attr,
    cellIndex: index,
    type: checkType(index)
  }));
};

export const generateMemoryLookups = source => {
  const data = source.data;

  const fields = [];
  source.fields.forEach(field => {
    field.count = 0;
    field.lookup = {};
    fields.push(field);
  });

  for (let i = data.length; i > 0; i--) {
    const row = data[i - 1];
    fields.forEach(field => {
      const value = row[field.cellIndex];
      if (!(value in field.lookup)) {
        field.lookup[value] = field.count++;
      }
    });
  }
  const lookups = {};
  fields.forEach(field => {
    lookups[field.id] = field.lookup;
  });

  return lookups;
};

export const getFilteredData = (pivot, source, Constants, allFieldsFlag) => {
  const mergeObjects = (obj1, obj2) => {
    const obj3 = {};
    for (let prop in obj1) {
      obj3[prop] = obj1[prop];
    }
    for (let prop in obj2) {
      obj3[prop] = obj2[prop];
    }
    return obj3;
  };

  const sheetFields = pivot.fields;
  const rows = pivot[Constants.controlType.ROWS];
  const columns = pivot[Constants.controlType.COLUMNS];
  const filters = pivot[Constants.controlType.FILTERS];
  const values = pivot[Constants.controlType.VALUES];

  let fields = [];
  let calculatedFields = [];
  let fieldsMap = {};

  source.fields.forEach(column => {
    const id = column.id;
    const field = mergeObjects(column, sheetFields[id] || {});
    field.usedByCalculated = false;
    fields.push(field);
    fieldsMap[id] = field;
  });

  for (let id in pivot.fields) {
    if (pivot.fields[id].type === Constants.fieldType.CALCULATED) {
      if (values.some(item => item.fieldId === id)) {
        const field = mergeObjects(pivot.fields[id], { calculatedIds: [] });
        fields.forEach(field1 => {
          if (field.formula.match(new RegExp("{" + field1.name + "}", "gi"))) {
            field1.usedByCalculated = true;
            field.calculatedIds.push(field1.id);
          }
        });
        calculatedFields.push(field);
        fieldsMap[id] = field;
      }
    }
  }

  let data = [];

  let controlIds = []
    .concat(rows)
    .concat(columns)
    .concat(values.map(item => item.fieldId));
  let usableFields = fields.filter(
    field => field.usedByCalculated && controlIds.indexOf(field.id) < 0
  );

  let filterIds = []
    .concat(filters)
    .concat(rows)
    .concat(columns)
    .filter(
      id =>
        fieldsMap[id].filter &&
        fieldsMap[id].filter.filterType === Constants.filterBy.LABEL
    );

  const memoryData = source.data;
  for (let i = memoryData.length; i > 0; i--) {
    const row = memoryData[i - 1];

    let filterOut = false;
    for (let j = filterIds.length; j > 0; j--) {
      const field = fieldsMap[filterIds[j - 1]];
      switch (field.filter.filterLabelType) {
        case Constants.filterLabel.INCLUDES:
          if (
            field.filter.filterLabelIncludes.indexOf(row[field.cellIndex]) < 0
          ) {
            filterOut = true;
          }
          break;

        case Constants.filterLabel.EXCLUDES:
          if (
            field.filter.filterLabelExcludes.indexOf(row[field.cellIndex]) >= 0
          ) {
            filterOut = true;
          }
          break;

        case Constants.filterLabel.FORMULA:
          break;

        default:  
      }
      if (filterOut) {
        break;
      }
    }
    if (filterOut) {
      continue;
    }

    const newRow = {};

    if (allFieldsFlag) {
      // for drill through
      source.fields.forEach(column => {
        newRow[column.id] = row[column.cellIndex];
      });
    } else {
      controlIds.forEach(id => {
        const field = fieldsMap[id];
        const value = row[field.cellIndex];
        newRow[id] = value;
      });

      usableFields.forEach(field => {
        newRow[field.id] = row[field.cellIndex];
      });
    }

    data.push(newRow);
  }

  return {
    data,
    fieldsMap
  };
};

export const generateMemoryPivotData = (pivot, filteredData, lookups, Constants) => {
  let rowTree = {},
    colTree = {},
    valueObj = {},
    valuesInAxis = pivot.valuesInAxis,
    valuesIndex = pivot.valuesIndex,
    locale = "en"; //fixme
  const { data, fieldsMap } = filteredData;
  const rows = pivot[Constants.controlType.ROWS].map(id => fieldsMap[id]);
  const columns = pivot[Constants.controlType.COLUMNS].map(id => fieldsMap[id]);
  const values = pivot[Constants.controlType.VALUES];

  if (
    rows.length === 0 &&
    columns.length === 0 &&
    values.length === 0
  ) {
    return {};
  }

  const statistics = {};
  statistics[Constants.statsOption.SUM] = a => {
    let sum = 0;
    for (let i = a.length; i > 0; i--) {
      sum += a[i - 1];
    }
    return sum;
  };

  statistics[Constants.statsOption.COUNT] = a => a.length;
  statistics[Constants.statsOption.AVERAGE] = a => {
    let sum = 0;
    for (let i = a.length; i > 0; i--) {
      sum += a[i - 1];
    }
    return sum / Math.max(a.length, 1);
  };
  statistics[Constants.statsOption.MAX] = a => Math.max.apply(null, a);
  statistics[Constants.statsOption.MIN] = a => Math.min.apply(null, a);
  statistics[Constants.statsOption.MEDIAN] = a => {
    const t = a.slice(0).sort((a, b) => a - b);
    return (t[Math.floor(a.length / 2)] + t[Math.ceil(a.length / 2)]) / 2;
  };
  statistics[Constants.statsOption.MODE] = a => {
    let o = {},
      r = 0,
      m = Number.MIN_VALUE;
    for (let i = a.length; i > 0; i--) {
      const v = a[i - 1];
      o[v] !== undefined ? o[v]++ : (o[v] = 1);
    }
    for (let p in o) {
      if (m < o[p]) {
        m = o[p];
        r = p;
      }
    }
    return r;
  };
  statistics[Constants.statsOption.STDDEV] = a => {
    let i,
      len = a.length,
      mean = 0,
      squares = 0;
    for (i = len; i > 0; i--) {
      mean += a[i - 1];
      squares += Math.pow(a[i - 1], 2);
    }
    return squares / len - Math.pow(mean / len, 2);
  };
  statistics[Constants.statsOption.VARIANCE] = a => {
    let i,
      len = a.length,
      mean = 0,
      squares = 0;
    for (i = len; i > 0; i--) {
      mean += a[i - 1];
      squares += Math.pow(a[i - 1], 2);
    }
    return Math.sqrt(squares / len - Math.pow(mean / len, 2));
  };
  statistics[Constants.statsOption.DISTINCTCOUNT] = a => {
    const counts = {};
    for (let i = 0, len = a.length; i < len; i++) {
      counts[a[i]] = 1 + (counts[a[i]] || 0);
    }
    const keys = [];
    for (let k in counts) keys.push(k);
    return keys.length;
  };
  //https://en.wikipedia.org/wiki/Percentile
  statistics[Constants.statsOption.PERCENTILEINC] = (a, p) => {
    if (a.length === 0 || !p) return NaN;
    const arr = a.sort((a, b) => a - b);
    if (p <= 0) return arr[0];
    if (p >= 100) return arr[arr.length - 1];

    let index = (arr.length - 1) * (p / 100),
      lower = Math.floor(index),
      upper = lower + 1,
      weight = index % 1;

    if (upper >= arr.length) return arr[lower];
    return arr[lower] * (1 - weight) + arr[upper] * weight;
  };
  statistics[Constants.statsOption.PERCENTILEEXC] = (a, p) => {
    if (a.length === 0 || !p) return NaN;

    if (p <= 100 / (a.length + 1)) return NaN;
    if (p >= (100 * a.length) / (a.length + 1)) return NaN;

    const arr = a.sort((a, b) => a - b);

    let index = (a.length + 1) * (p / 100) - 1,
      lower = Math.floor(index),
      upper = lower + 1,
      weight = index % 1;
    if (upper >= arr.length) return arr[lower];
    return arr[lower] * (1 - weight) + arr[upper] * weight;
  };

  const calcStatsValue = (dataSet, valueField) => {
    const field = fieldsMap[valueField.fieldId];

    let arr = [],
      value;

    if (
      valueField.stats.toUpperCase() === Constants.statsOption.DISTINCTCOUNT &&
      field.type !== Constants.fieldsType.CALCULATED
    ) {
      for (let i = dataSet.length; i > 0; i--) {
        arr.push(dataSet[i - 1][field.id]);
      }
      value = statistics[valueField.stats.toUpperCase()](arr);
    } else if (field.type === Constants.fieldType.NUMBER) {
      for (let i = dataSet.length; i > 0; i--) {
        let label = dataSet[i - 1][field.id];
        if (label !== null && label !== "") {
          arr.push(Number(label));
        }
      }
      value = statistics[valueField.stats.toUpperCase()](
        arr,
        valueField.statsParameter
      );
    } else if (field.type === Constants.fieldType.CALCULATED) {
      const total ={};
      const calculatedIds = field.calculatedIds;
      for(let j = 0; j < calculatedIds.length; j++) {
          const field1 = fieldsMap[calculatedIds[j]];
          total[field1.name] = 0;
      }
      for (let i = dataSet.length - 1; i >= 0; i--) {
          for(let j = 0; j < calculatedIds.length; j++){
            const field1 = fieldsMap[calculatedIds[j]];
            total[field1.name] += Number(dataSet[i][field1.name]);
          }
      }

      let formula = field.formula;
      for(let j = 0; j < calculatedIds.length; j++){
        const field1 = fieldsMap[calculatedIds[j]];
        formula = formula.replace(new RegExp('{'+(field1.name)+'}', 'gi'),
            '('+total[field1.name]+')');
      }
      try{
          value = eval(formula); /* eslint no-eval: 0 */
      }catch(e){
          value = Math.NaN;
      }
    } else {
      value = dataSet.length;
    }
    return value;
  };

  const fieldFilterByValue = (axisIndex, pivotField, items) => {
    if (pivotField && pivotField.filter) {
      if (pivotField.filter.filterType === Constants.filterBy.VALUE) {
        let vIndex = -1;
        for (let i = 0; i < values.length; i++) {
          if (values[i].id === pivotField.filter.filterValueId) {
            vIndex = i;
            break;
          }
        }
        if (vIndex >= 0) {
          return items.filter(function(item) {
            const prop =
              axisIndex === 0
                ? "R-" + item.id.replace(/V[0-9]*/, "") + "-V" + vIndex
                : item.id.replace(/V[0-9]*/, "") + "-C-V" + vIndex;
            const value = valueObj[prop];
            let testFunc = null;

            switch (pivotField.filter.filterValueOperator) {
              case Constants.compareOption.EQUALS: //equals
                testFunc = function() {
                  return (
                    parseFloat(value) ===
                    parseFloat(pivotField.filter.filterValue)
                  );
                };
                break;
              case Constants.compareOption.NOT_EQUALS: //does not equal
                testFunc = function() {
                  return (
                    parseFloat(value) !==
                    parseFloat(pivotField.filter.filterValue)
                  );
                };
                break;
              case Constants.compareOption.GREAT_THAN: //is great than
                testFunc = function() {
                  return (
                    parseFloat(value) >
                    parseFloat(pivotField.filter.filterValue)
                  );
                };
                break;
              case Constants.compareOption.GREAT_EQUAL: //is great than or equal to
                testFunc = function() {
                  return (
                    parseFloat(value) >=
                    parseFloat(pivotField.filter.filterValue)
                  );
                };
                break;
              case Constants.compareOption.LESS_THAN: //is less than
                testFunc = function() {
                  return (
                    parseFloat(value) <
                    parseFloat(pivotField.filter.filterValue)
                  );
                };
                break;
              case Constants.compareOption.LESS_EQUAL: //is less than or equal to
                testFunc = function() {
                  return (
                    parseFloat(value) <=
                    parseFloat(pivotField.filter.filterValue)
                  );
                };
                break;
              case Constants.compareOption.BETWEEN: //is between
                testFunc = function() {
                  return (
                    parseFloat(value) >=
                      parseFloat(pivotField.filter.filterValue) &&
                    parseFloat(value) <=
                      parseFloat(pivotField.filter.filterValue1)
                  );
                };
                break;
              case this.Constants.compareOption.NOT_BETWEEN: //is not between
                testFunc = function() {
                  return (
                    parseFloat(value) <
                      parseFloat(pivotField.filter.filterValue) &&
                    parseFloat(value) >
                      parseFloat(pivotField.filter.filterValue1)
                  );
                };
                break;
              default:
            }
            if (testFunc) {
              return testFunc();
            }
            return false;
          });
        }
      } else if (pivotField.filter.filterType === Constants.filterBy.TOP) {
        let vIndex = -1;
        for (let i = 0; i < values.length; i++) {
          if (values[i].id === pivotField.filter.filterTopValueId) {
            vIndex = i;
            break;
          }
        }

        if (vIndex >= 0) {
          items.sort(function(a, b) {
            const a_prop =
              axisIndex === 0
                ? "R-" + a.id.replace(/V[0-9]*/, "") + "-V" + vIndex
                : a.id.replace(/V[0-9]*/, "") + "-C-V" + vIndex;
            const b_prop =
              axisIndex === 0
                ? "R-" + b.id.replace(/V[0-9]*/, "") + "-V" + vIndex
                : b.id.replace(/V[0-9]*/, "") + "-C-V" + vIndex;
            const compareValue = valueObj[a_prop] - valueObj[b_prop];
            return (
              (pivotField.filter.filterTopOperator === Constants.compareOption.TOP
                ? -1
                : 1) * compareValue
            );
          });
          return items.slice(0, parseInt(pivotField.filter.filterTopValue, 10));
        }
      }
    }
    return items;
  };

  const generateValueObj = (dataSet, colItemId, rowIndex, rowItem) => {
    if (rowIndex < rows.length) {
      const filterData = {};
      rowItem.children = [];

      const field = rows[rowIndex];
      for (let i = dataSet.length; i > 0; i--) {
        const fValue = dataSet[i - 1][field.id];
        filterData[fValue] = filterData[fValue] || [];
        filterData[fValue].push(dataSet[i - 1]);
      }

      const pivotField = pivot.fields[field.id];

      for (let label in filterData) {
        const key = lookups[field.id][label];

        const childItem = { id: rowItem.id + "-" + key };

        generateValueObj(filterData[label], colItemId, rowIndex + 1, childItem);

        rowItem.children.push(childItem);
      }

      rowItem.children = fieldFilterByValue(1, pivotField, rowItem.children);

      rowItem.data = [];
      for (let j = rowItem.children.length; j > 0; j--) {
        rowItem.data = rowItem.data.concat(rowItem.children[j - 1].data);
      }
    } else {
      rowItem.data = dataSet;
    }

    for (let i = 0, len = values.length; i < len; i++) {
      const prop = rowItem.id + "-" + colItemId + "-V" + i;
      if (!(prop in valueObj)) {
        valueObj[prop] = calcStatsValue(rowItem.data, values[i]);
      }
    }
  };

  const generateAxisTree = (axisIndex, axis, axisTree) => {
    const dataFlag = axisIndex === 0 && values.length > 0;

    const addTreeItem = (dataSet, treeItem, index, id) => {
      treeItem.id = id;
      if (index < axis.length) {
        const filterData = {};
        treeItem.children = [];

        const field = axis[index];
        for (let i = dataSet.length; i > 0; i--) {
          const fValue = dataSet[i - 1][field.id];
          filterData[fValue] = filterData[fValue] || [];
          filterData[fValue].push(dataSet[i - 1]);
        }

        const pivotField = pivot.fields[field.id];

        for (let label in filterData) {
          const key = lookups[field.id][label];

          if (valuesInAxis === axisIndex && index === valuesIndex) {
            for (let i = 0, len = values.length; i < len; i++) {
              const childItem = { label };
              addTreeItem(
                filterData[label],
                childItem,
                index + 1,
                id + "V" + i + "-" + key
              );
              treeItem.children.push(childItem);
            }
          } else {
            const childItem = { label };
            addTreeItem(
              filterData[label],
              childItem,
              index + 1,
              id + "-" + key
            );
            treeItem.children.push(childItem);
          }
        }

        treeItem.children = fieldFilterByValue(
          axisIndex,
          pivotField,
          treeItem.children
        );

        if (dataFlag) {
          treeItem.data = [];
          if (valuesInAxis === axisIndex && index === valuesIndex) {
            treeItem.data = treeItem.children[0].data;
          } else {
            for (let j = treeItem.children.length; j > 0; j--) {
              treeItem.data = treeItem.data.concat(
                treeItem.children[j - 1].data
              );
            }
          }
        }

        if (
          pivotField &&
          pivotField.sort &&
          pivotField.sort.sortType &&
          pivotField.sort.sortType !== -1
        ) {
          if (pivotField.sort.sortType === Constants.sortBy.LABEL) {
            treeItem.children.sort(function(a, b) {
              const compareValue =
                field.type !== Constants.fieldType.NUMBER
                  ? a.label.localeCompare(b.label, locale)
                  : parseFloat(a.label) - parseFloat(b.label);

              return (pivotField.sort.sortDescend ? -1 : 1) * compareValue;
            });
          } else if (pivotField.sort.sortType === Constants.sortBy.VALUE) {
            let vIndex = -1;
            for (let i = 0; i < values.length; i++) {
              if (values[i].id === pivotField.sort.sortValueId) {
                vIndex = i;
                break;
              }
            }
            if (vIndex >= 0) {
              treeItem.children.sort(function(a, b) {
                const a_prop =
                  axisIndex === 0
                    ? "R-" + a.id.replace(/V[0-9]*/, "") + "-V" + vIndex
                    : a.id.replace(/V[0-9]*/, "") + "-C-V" + vIndex;
                const b_prop =
                  axisIndex === 0
                    ? "R-" + b.id.replace(/V[0-9]*/, "") + "-V" + vIndex
                    : b.id.replace(/V[0-9]*/, "") + "-C-V" + vIndex;
                const compareValue = valueObj[a_prop] - valueObj[b_prop];
                return (pivotField.sort.sortDescend ? -1 : 1) * compareValue;
              });
            }
          }
        }
      } else {
        if (dataFlag) {
          treeItem.data = dataSet;
        }
      }

      if (dataFlag) {
        generateValueObj(treeItem.data, treeItem.id.replace(/V[0-9]*/, ""), 0, {
          id: "R"
        });
        if (treeItem.children) {
          for (let j = treeItem.children.length; j > 0; j--) {
            delete treeItem.children[j - 1].data;
          }
        }
        if (index === 0) {
          delete treeItem.data;
        }
      }
    };

    if (axis.length > 0 || values.length > 0) {
      addTreeItem(data, axisTree, 0, axisIndex === 0 ? "C" : "R");
    }
  };

  generateAxisTree(0, columns, colTree);

  generateAxisTree(1, rows, rowTree);

  return {
    colTree,
    rowTree,
    valueObj
  };
};
