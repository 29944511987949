import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useAppContext } from "../../lib/contextLib";
import { useFormFields } from "../../lib/hooksLib";
import { onError } from "../../lib/errorLib";
import FacebookButton from "../../components/FacebookButton";


export default function Login() {
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: ""
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const response = await Auth.signIn(fields.email, fields.password);
      console.log("acount login success response:", response);
      userHasAuthenticated(true);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  return (
    <div>
      <FacebookButton />      
      <hr/>
      <form onSubmit={handleSubmit} style={{display: "flex", flexDirection: "column"}}>
        <TextField
            id="email"
            label="Email"
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />        
        <TextField
            id="password"
            label="Password"
            value={fields.password}
            type="password"
            onChange={handleFieldChange}
          />   
        <Link to="/login/reset">Forgot password?</Link> 
        <LoadingButton
          loading={isLoading}
          disabled={!validateForm()}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="outlined"
          type="submit"
        >
          Login
        </LoadingButton>

      </form>
      
    </div>
  );
}