import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { API, Storage } from "aws-amplify";
import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
// import config from "../../config";
// import { s3Upload } from "../../lib/awsLib";
import { onError } from "../../lib/errorLib";

export default function Sheet() {
  // const file = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [sheet, setSheet] = useState(null);
  const [sheetName, setSheetName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    function loadSheet() {
      return API.get("wpt-app", `/sheets/${id}`);
    }

    async function onLoad() {
      try {
        const sheet = await loadSheet();
        console.log("sheet", sheet);


        if (sheet.sheetFileKey) {
          sheet.attachmentURL = await Storage.vault.get(sheet.sheetFileKey);
          // sheet.body = await s3DownloadSheet(attachment)
        }

        setSheetName(sheet.sheetName);
        setSheet(sheet);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [id]);

  function validateForm() {
    return sheetName.length > 0;
  }
  
  // function formatFilename(str) {
  //   return str.replace(/^\w+-/, "");
  // }
  
  // function handleFileChange(event) {
  //   file.current = event.target.files[0];
  // }
  
  function saveSheet(sheet) {
    return API.put("wpt-app", `/sheets/${id}`, {
      body: sheet
    });
  }
  
  async function handleSubmit(event) {
  
    event.preventDefault();
  
  
    setIsLoading(true);
  
    try {
  
      await saveSheet({
        sheetName,
        sheetFileKey: sheet.sheetFileKey
      });
      navigate("/sheets");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }
  
  function deleteSheet() {
    return API.del("wpt-app", `/sheets/${id}`);
  }
  
  async function handleDelete(event) {
    event.preventDefault();
  
    const confirmed = window.confirm(
      "Are you sure you want to delete this sheet?"
    );
  
    if (!confirmed) {
      return;
    }
  
    setIsDeleting(true);
  
    try {
      await deleteSheet();
      navigate("/sheets");
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }
  
  return (
    <div>
      {sheet && sheet.sheet && (<p> {JSON.stringify(sheet.sheet)}</p>)}

      {sheet && (
        <form onSubmit={handleSubmit} style={{display: "flex", flexDirection: "column"}}>
          <TextField
            label="Sheet name"
            value={sheetName}
            onChange={(e) => setSheetName(e.target.value)}
          />        


          <LoadingButton
            loading={isLoading}
            disabled={!validateForm()}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
            type="submit"
          >
            Save
          </LoadingButton>
          <LoadingButton
            loading={isDeleting}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
            onClick={handleDelete}
          >
            Delete
          </LoadingButton>

        </form>
      )}
    </div>
  );
}