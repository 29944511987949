import React from "react";
import { useAppContext } from "../lib/contextLib";
import Paper from '@mui/material/Paper';


export default function Wpt() {
  const { isAuthenticated } = useAppContext();

  return (
    <Paper>
      <h1>WPT Dashboard </h1>
      <p>isAuthenticated {isAuthenticated}</p>
      <p>Add source data</p>
      <p>Add sheet</p>
    </Paper>
  );
}